import {
  EditorReadyOptions,
  EditorSDK,
  EditorType,
  AppManifest,
  EventType,
} from '@wix/platform-editor-sdk';
import { onEditorReady } from './editor-app/editorReady';
import { modifyAppManifest } from './editor-app/modifyAppManifest';

export function editorReady(
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  editorType: EditorType,
): Promise<void> {
  return onEditorReady(editorSDK, appDefId, options, editorType);
}

export const getAppManifest = async (
  options,
  editorSDK: EditorSDK,
): Promise<AppManifest> => {
  return modifyAppManifest(options, editorSDK);
};
