import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import { ProjectPageWidgetComponentIds } from './config/constants';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';

export const getWidgetManifest: GetWidgetManifestFn = async (
  controllerBuilder,
  editorSDK,
  flow,
) => {
  controllerBuilder.set({
    displayName: 'Project Page Widget',
    nickname: 'projectPageWidget',
  });
  controllerBuilder.set({
    displayName: 'Project Page Widget',
    nickname: 'projectPageWidget',
  });

  controllerBuilder
    .gfpp()
    .set('layout', { actionId: 'openProjectLayoutPanel' });
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: 'Project Widget Design',
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignTabsBuilder.addTab((tabBuilder) => {
      tabBuilder
        .set({
          label: 'Project',
        })
        .groups()
        .set({
          roles: [ProjectPageWidgetComponentIds.ProjectWidget].map(getRole),
        });
    });
  });
};
