import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../editor-app/manifest/getRole';
import {
  ProjectHeaderWidgetComponentIds,
  ProjectHeaderWidgetPresets,
} from './config/constants';

export const getWidgetManifest: GetWidgetManifestFn = (controllerBuilder) => {
  controllerBuilder.set({
    displayName: 'Project Header Widget',
    nickname: 'projectHeaderWidget',
  });
  controllerBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: 'Project Header Design',
    });

    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();
    widgetDesignBuilder.setPresets(ProjectHeaderWidgetPresets);
    widgetDesignTabsBuilder
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Container',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Root].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Title',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Title].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Subtitle',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.SubTitle].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'Description',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.Description].map(getRole),
          });
      })
      .addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: 'HeroImage',
          })
          .groups()
          .set({
            roles: [ProjectHeaderWidgetComponentIds.HeroImage].map(getRole),
          });
      });
  });
};
