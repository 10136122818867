import { EditorSDK, EditorType } from '@wix/platform-editor-sdk';
import type { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { handleFirstInstall } from './first-install/handleFirstInstall';
import { setPortfolioPagesState } from './first-install/utils/setPortfolioPagesState';
import { editorAppEventListener } from './editorAppEventListener';

export const onEditorReady = async (
  editorSDK: EditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  editorType: EditorType,
): Promise<void> => {
  if (options.firstInstall) {
    await handleFirstInstall(editorSDK, appDefId, options);
  }
  setPortfolioPagesState(editorSDK);
  await editorAppEventListener(editorSDK, editorType);
};
