import { EditorSDK, EditorType, EventType } from '@wix/platform-editor-sdk';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';

export const editorAppEventListener = async (
  editorSDK: EditorSDK,
  editorType: EditorType,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    editorSDK,
    editorType,
  );

  await editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case 'openPortfolioLayoutPanel':
        void panelsApi.openBlocksPanel('portfolioLayoutPanel', componentRef);
        break;
      case 'openProjectLayoutPanel':
        void panelsApi.openBlocksPanel('projectLayoutPanel', componentRef);
        break;
    }
  });
  await editorSDK.addEventListener('appActionClicked', (event) => {
    const { actionId } = event.detail;
    switch (actionId) {
      case 'addNewCategory':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/category',
          closeOtherPanels: false,
        });
        break;
      case 'addNewProject':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio/project',
          closeOtherPanels: false,
        });
        break;
      case 'navigateToDashboard':
        editorSDK.editor.openDashboardPanel('', {
          url: '/portfolio',
          closeOtherPanels: false,
        });
        break;
    }
  });
  // event for delete app page -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioPage' as EventType,
    (event) => {
      const { pageRef } = event.detail;
      editorSDK.pages.remove('', {
        pageRef,
        shouldShowEditorRemovePanel: false,
      });
    },
  );
  // event for delete app -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioApp' as EventType,
    (event) => {
      editorSDK.application.uninstall('', { openConfirmation: true });
    },
  );
};
