
  var editorScriptEntry = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = {
        DSN: 'https://bfb679c754744c58a7374ee6e25cfc13@sentry.wixpress.com/2954',
        id: 'bfb679c754744c58a7374ee6e25cfc13',
        projectName: 'portfolio-bob-xj',
        teamName: 'photography-portfolio',
        
      };
      const experimentsConfig = {"centralized":true,"scopes":[]};
      const translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};
      const defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        shouldUseEssentials: true,
        artifactId: 'portfolio-bob',
        appDefId: 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesGalleryWidget/editor.controller.ts');

      const model_0 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesGalleryWidget/model.ts').default;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-itb6f";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-itb6f": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-itb6f", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);
  
      const userController_1 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesListWidget/editor.controller.ts');

      const model_1 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoriesListWidget/model.ts').default;

      const manifest_1 = userController_1 ? userController_1.default || userController_1 : {};
      if (!manifest_1.type) {
        manifest_1.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-waybk";
      }
      if (!manifest_1.getExports && model_1) {
        manifest_1.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-waybk": model_1.getExports()});
      }
      if (userController_1 && userController_1.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-waybk", getWidgetManifest: userController_1.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_1);
  
      const userController_2 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryPageWidget/editor.controller.ts');

      const model_2 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryPageWidget/model.ts').default;

      const manifest_2 = userController_2 ? userController_2.default || userController_2 : {};
      if (!manifest_2.type) {
        manifest_2.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8";
      }
      if (!manifest_2.getExports && model_2) {
        manifest_2.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8": model_2.getExports()});
      }
      if (userController_2 && userController_2.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-voiu8", getWidgetManifest: userController_2.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_2);
  
      const userController_3 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryWidget/editor.controller.ts');

      const model_3 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/categoryWidget/model.ts').default;

      const manifest_3 = userController_3 ? userController_3.default || userController_3 : {};
      if (!manifest_3.type) {
        manifest_3.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-os5sm";
      }
      if (!manifest_3.getExports && model_3) {
        manifest_3.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-os5sm": model_3.getExports()});
      }
      if (userController_3 && userController_3.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-os5sm", getWidgetManifest: userController_3.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_3);
  
      const userController_4 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/navigationWidget/editor.controller.ts');

      const model_4 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/navigationWidget/model.ts').default;

      const manifest_4 = userController_4 ? userController_4.default || userController_4 : {};
      if (!manifest_4.type) {
        manifest_4.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-zmzxw";
      }
      if (!manifest_4.getExports && model_4) {
        manifest_4.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-zmzxw": model_4.getExports()});
      }
      if (userController_4 && userController_4.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-zmzxw", getWidgetManifest: userController_4.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_4);
  
      const userController_5 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/portfolioPageWidget/editor.controller.ts');

      const model_5 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/portfolioPageWidget/model.ts').default;

      const manifest_5 = userController_5 ? userController_5.default || userController_5 : {};
      if (!manifest_5.type) {
        manifest_5.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf";
      }
      if (!manifest_5.getExports && model_5) {
        manifest_5.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf": model_5.getExports()});
      }
      if (userController_5 && userController_5.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-n0thf", getWidgetManifest: userController_5.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_5);
  
      const userController_6 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectHeaderWidget/editor.controller.ts');

      const model_6 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectHeaderWidget/model.ts').default;

      const manifest_6 = userController_6 ? userController_6.default || userController_6 : {};
      if (!manifest_6.type) {
        manifest_6.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vvhj5";
      }
      if (!manifest_6.getExports && model_6) {
        manifest_6.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vvhj5": model_6.getExports()});
      }
      if (userController_6 && userController_6.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vvhj5", getWidgetManifest: userController_6.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_6);
  
      const userController_7 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectPageWidget/editor.controller.ts');

      const model_7 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectPageWidget/model.ts').default;

      const manifest_7 = userController_7 ? userController_7.default || userController_7 : {};
      if (!manifest_7.type) {
        manifest_7.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe";
      }
      if (!manifest_7.getExports && model_7) {
        manifest_7.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe": model_7.getExports()});
      }
      if (userController_7 && userController_7.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-yaafe", getWidgetManifest: userController_7.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_7);
  
      const userController_8 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectWidget/editor.controller.ts');

      const model_8 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/projectWidget/model.ts').default;

      const manifest_8 = userController_8 ? userController_8.default || userController_8 : {};
      if (!manifest_8.type) {
        manifest_8.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-gpt2l";
      }
      if (!manifest_8.getExports && model_8) {
        manifest_8.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-gpt2l": model_8.getExports()});
      }
      if (userController_8 && userController_8.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-gpt2l", getWidgetManifest: userController_8.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_8);
  
      const userController_9 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/tagsPageWidget/editor.controller.ts');

      const model_9 = require('/home/builduser/work/80801144f80bb816/packages/portfolio-bob/src/components/tagsPageWidget/model.ts').default;

      const manifest_9 = userController_9 ? userController_9.default || userController_9 : {};
      if (!manifest_9.type) {
        manifest_9.type = "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vp33g";
      }
      if (!manifest_9.getExports && model_9) {
        manifest_9.getExports = () => ({"d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vp33g": model_9.getExports()});
      }
      if (userController_9 && userController_9.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "d90652a2-f5a1-4c7c-84c4-d4cdcc41f130-vp33g", getWidgetManifest: userController_9.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_9);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
