export const configureManagementActions = (managementActionsBuilder) => {
  managementActionsBuilder.mainActions().addAction(
    {
      title: 'Add New Category',
      actionId: 'addNewCategory',
      icon: 'create',
    },
    {
      title: 'Add New Project',
      actionId: 'addNewProject',
      icon: 'create',
    },
  );

  managementActionsBuilder.customActions().addAction({
    title: 'Portfolio Settings',
    icon: 'openPagesPanelUrl',
    type: 'dashboard',
    actionId: 'navigateToDashboard',
  });

  managementActionsBuilder.upgradeAction().set({
    upgradeType: 'SITE_UPGRADE',
    upgradeText: 'to get advance features,',
    upgradeLinkText: 'upgrade Ascend.',
  });

  managementActionsBuilder.learnMoreAction().set({
    id: '3478478-394839489-39849358',
  });
};
